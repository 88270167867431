import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import { createBrowserHistory } from 'history';
import JwtAuthentication from '@iso/lib/jwt-auth/jwtAuthentication';

import { getUserData, clearUserData, setUserData } from '@iso/lib/helpers/utility';
import actions from './actions';
import notification from '@iso/components/Notification';

const history = createBrowserHistory();

export function* loginRequest() {
  yield takeEvery('LOGIN_REQUEST', function*({ payload }) {
    const result = yield call(JwtAuthentication.login, payload.userInfo);
    if (result.success) {
      if (!result.checkTwoStepVerification) {
        yield setUserData(result.token,result.userName,'null',result.twoStepVerificationIsActive);
      }
      yield put({
        type: actions.LOGIN_SUCCESS,
        token: result.token,
        userName: result.userName?result.userName:'',
        twoStepVerificationIsActive: result.twoStepVerificationIsActive
      });
      //history.push('/dashboard');
    } else {
      notification('error', result.error?result.error:'Sorry, there was an error with your request');
      yield put({ type: actions.LOGIN_ERROR });
    }
  });
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function*(payload) {
    let twoStepVerification = getUserData().get('twoStepVerification');
    const twoStepVerificationIsActive = payload.twoStepVerificationIsActive;
    yield setUserData(payload.token,payload.userName,twoStepVerification,twoStepVerificationIsActive);
    if ( twoStepVerificationIsActive === '1' &&
          (!twoStepVerification || twoStepVerification === 'undefined' || twoStepVerification === 'null')
    ) {
      history.push('/twoStepVerification');
    } else {
      if (history.location.pathname === '/') {
        yield history.push('/dashboard');
      }
    }
  });
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function*() {
    yield clearUserData();
    history.push('/');
  });
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function*() {
    yield call(JwtAuthentication.logout, {});
    yield clearUserData();
    history.push('/');
  });
}
export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function*() {
    const token = getUserData().get('idToken');
    const userName = getUserData().get('userName');
    const twoStepVerificationIsActive = getUserData().get('twoStepVerificationIsActive');
    if (token) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        token,
        userName,
        twoStepVerificationIsActive,
      });
    } else {
      yield put({ type: actions.LOGIN_ERROR });
    }
  });
}

export function* twoStepVerificationSuccess () {// add to root saga
  yield takeEvery(actions.TWO_STEP_VERIFICATION_SUCCESS, function*(payload) {
    const token = getUserData().get('idToken');
    const userName = getUserData().get('userName');
    const twoStepVerificationIsActive = getUserData().get('twoStepVerificationIsActive');
    const twoStepVerification = payload.twoStepVerification;
    yield setUserData(
      token,
      userName,
      twoStepVerification,
      twoStepVerificationIsActive,
    );
    yield history.push('/dashboard');
    yield window.location.reload()
  });
}

export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(loginRequest),
    fork(loginSuccess),
    fork(loginError),
    fork(logout),
    fork(twoStepVerificationSuccess),
  ]);
}
