const BaseURL = 'https://lalp-staging.melian.me/api';
const siteName = 'Latin America Leadership Program';
const currentProject = 'Alumni';
const logos = {
  logoLogin: 'logo_lalp_texto_oscuro.svg',
  logoSidebar: 'logo_lalp.svg',
  logoTopbar: 'logo_lalp_texto_oscuro.svg',
  backgroundLogin: 'img_background_splash1-01.png',
  loadingImage: 'loading.gif',
}

export { 
  BaseURL,
  siteName,
  currentProject,
  logos
}
