import axios from 'axios';
import { BaseURL } from './config';

const api = axios.create({
  baseURL: BaseURL
});

/*const images = {
  logoTopbar: 'logo_main.svg',
  logoLogin: 'logo_main_white_background.svg',
  backgroundLogin: 'login_background.jpg',
}*/

export default {
  siteName: 'MUJERES 360',
  siteIcon: 'ion-flash',
  footerText: `Melian @ ${new Date().getFullYear()}`,
  enableAnimatedRoute: false,
  apiUrl: 'http://yoursite.com/api/',
  google: {
    analyticsKey: 'UA-xxxxxxxxx-1',
  },
  dashboard: '/dashboard',
};

export { 
  api,
  //images,
};
