import { api } from '@iso/config/site.config';
import { clearUserData } from '@iso/lib/helpers/utility';
import { userApiUrls } from '@iso/currentProject/currentProjectUserFieldsOptions'

class JwtAuth {
  login = async userInfo => {
    if (!userInfo.email || !userInfo.password) {
      return { error: 'Please fill in the input' };
    }
    return await api.post(userApiUrls.login, userInfo)
      .then(response => response.data)
      .then(res => {
        const result = {};
        if (res.token) {
          result.token = res.token;
          result.success = res.success;
          result.userName = res.user_name
          result.twoStepVerificationIsActive = res.two_step_verification_is_active
          result.checkTwoStepVerification = res.two_step_verification
          return result;
        } else {
          return res;
        }
      })
      .catch(error => ({ error }));
  };

  logout = async () => {
    return await api.post('auth/logout', {})
      .then(response => response.data)
      .then(res => {
        clearUserData();
      })
      .catch(error => ( console.log(error) ));
  }
}

export default new JwtAuth();
