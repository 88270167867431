const currentProjectFieldsOptions = [
  {
    field: 'optional_email',
    label: 'Optional Email',
    type: 'text',
    required: false,
  },
  {
    field: 'phonenumber',
    label: 'Phone Number',
    type: 'text',
    required: true,
  },
  {
    field: 'birth_date',
    label: 'Birth Date',
    type: 'datePicker',
    required: true,
  },
  {
    field: 'institution',
    label: 'Institution',
    type: 'text',
    required: false,
  },
  {
    field: 'job_function',
    label: 'Job Function',
    type: 'text',
    required: false,
  },
  {
    field: 'facebook',
    label: 'Facebook',
    type: 'text',
    required: false,
  },
  {
    field: 'linkedin',
    label: 'Linkedin',
    type: 'text',
    required: false,
  },
  {
    field: 'youtube',
    label: 'Youtube',
    type: 'text',
    required: false,
  },
  {
    field: 'twitter',
    label: 'Twitter',
    type: 'text',
    required: false,
  },
  {
    field: 'instagram',
    label: 'Instagram',
    type: 'text',
    required: false,
  },
  {
    field: 'personal_website',
    label: 'Personal Website',
    type: 'text',
    required: false,
  },
  {
    field: 'skype',
    label: 'Skype',
    type: 'text',
    required: false,
  },
  {
    field: 'optional_phonenumber',
    label: 'Optional Phone Number',
    type: 'text',
    required: false,
  },
  {
    field: 'user-location-city',
    label: 'User Location City',
    type: 'select',
    mode: 'multiple',
    required: false,//true
    options: {
      fields: {'user-location-city': []}
    },
  },
  {
    field: 'user-residence-location',
    label: 'User Residence Location',
    type: 'select',
    mode: 'default',
    required: false,
    options: {
      fields: {'user-residence-location': []}
    },
  },
  {
    field: 'user-sector',
    label: 'User Sector',
    type: 'select',
    mode: 'multiple',
    required: false,
    options: {
      fields: {'user-sector': []}
    },
  },
  {
    field: 'user-interest-area',
    label: 'Areas of Interest',
    type: 'select',
    mode: 'multiple',
    required: false,
    options: {
      fields: {'user-interest-area': []}
    },
  },
  {
    field: 'profession',
    label: 'Profession',
    type: 'wysiwyg',
    required: false,
  },
  {
    field: 'skills',
    label: 'Skills',
    type: 'wysiwyg',
    required: false,
  },
  {
    field: 'achievements_or_awards',
    label: 'Achievements Or Awards',
    type: 'wysiwyg',
    required: false,
  },
  {
    field: 'experience',
    label: 'Experience',
    type: 'wysiwyg',
    required: false,
  },
  {
    field: 'hobbies',
    label: 'Hobbies',
    type: 'wysiwyg',
    required: false,
  },
  {
    field: 'higher_education',
    label: 'Higher Education',
    type: 'wysiwyg',
    required: false,
  },
  {
    field: 'language',
    label: 'Language',
    type: 'text',
    required: false,
  },
  {
    field: 'slug',
    label: 'Slug',
    type: 'text',
    required: false,
  },
  {
    field: 'username',
    label: 'User Name',
    type: 'text',
    required: true,
  },
  {
    field: 'is_searchable',
    label: 'Is Searchable',
    type: 'select',
    mode: 'default',
    required: true,
    options: 'options_is_searchable',
  },
  {
    field: 'is_active',
    label: 'Is Active',
    type: 'select',
    mode: 'default',
    required: true,
    options: 'options_is_active',
  },
  {
    field: 'is_admin',
    label: 'Is Admin',
    type: 'select',
    mode: 'default',
    required: true,
    options: 'options_is_admin',
  },
  {
    field: 'user-gender',
    label: 'User Gender',
    type: 'select',
    mode: 'default',
    required: false,
    options: {
      fields: {'user-gender': []}
    },
  },
  {
    field: 'user-generation',
    label: 'User Generation',
    type: 'select',
    mode: 'multiple',
    required: false,
    options: {
      fields: {'user-generation': []}
    },
  },
  {
    field: 'userseal-granted',
    label: 'Userseal Granted',
    type: 'select',
    mode: 'default',
    required: false,
    options: {
      fields: {'userseal-granted': []}
    },
  },
  {
    field: 'is_confidential',
    label: 'Hide public profile',
    type: 'select',
    mode: 'default',
    required: false,
    options: 'options_is_admin',
  },
];

const userApiUrls = {
  addUser: 'admin/user/add', //rel
  getUser: 'admin/user/get/', //getInfo
  login: 'auth/admin/login', //signin
};

const currentProjectAddFieldsOptions = [];

export {
  currentProjectFieldsOptions,
  currentProjectAddFieldsOptions,
  userApiUrls,
}
