import React from 'react';
import LoaderComponent from './loader.style';
import { logos } from '@iso/config/config';

export default Loader => (
  <LoaderComponent>
    <img
      alt="Melian"
      src={process.env.PUBLIC_URL + "/images/images_config/" + logos.loadingImage}
      className="isoContentLoader"
    />
  </LoaderComponent>
);
