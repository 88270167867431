import defaultTheme from './default';
import currentProjectCustomTheme from '@iso/currentProject/currentProjectCustomTheme';

export default {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    //...defaultTheme.palette.primary,
    //primary: ['red'],
    //secondary: ['#0f0'],
    /*customPrimary: [
      '#041e42',
      '#032D56',
    ],
    bgTopbar: ['#ffffff'],
    textTopbar: ['#333333'],*/
    ...currentProjectCustomTheme,
  },
};
