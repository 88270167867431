import { api } from '@iso/config/site.config';
import { createBrowserHistory } from 'history';
import { getUserData, clearUserData } from '@iso/lib/helpers/utility';
import notification from '@iso/components/Notification';

const history = createBrowserHistory();

//Request interceptor
api.interceptors.request.use(function(config) {
  var token = getUserData().get('idToken');
  if ( token != null ) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
}, function(err) {
  return Promise.reject(err);
});

//Response interceptor
api.interceptors.response.use(function(response) {
  if (typeof response.data.status !== 'undefined') {
    if (response.data.status === 'Token is Expired') {
      notification('error', 'Please login again.');
      clearUserData();
      history.push({ pathname: '/signin' });
    }
  }
  if ((typeof response.data.success !== 'undefined') && (response.data.error === "User not authorized.")) {
    notification('error', 'Please login again.');
      clearUserData();
      history.push({ pathname: '/signin' });
  }
  return response;

}, function(err) {

  if (err.response) {
    if (err.response.status === 401) {
      clearUserData();
      history.push({ pathname: '/signin' });
      
      let do_not_show_error = err.response.data.do_not_show_error;
      if(!(do_not_show_error)){

      } else {
        notification('error', 'Please login again.');
      }
    } else if(err.response.status === 405 || err.response.status === 500) {
      notification('error', 'Sorry, there was an error with your request.');
    } else {
      notification('error', 'Connection error. Please try again.');
    }
  } else {
    // console.log(JSON.stringify(err));
    notification('error', 'Connection error. Please try again.');
  }
  return Promise.reject(err);

});
